<template>
  <div class="form-group row justify-content-between">
    <label :for="db_field + '_field'" :class="{ 'col-sm-4': !fullWidth, 'col-sm-12': fullWidth }" class="col-form-label"
           v-if="label.length !== 0">{{
        label
      }}
    </label>

    <div :class="!fullWidth ? 'col-9 col-md-11' : 'col-12'">
      <Multiselect
        autocomplete="off"
        list="autocompleteOff"
        :options="options"
        :searchable="searchable"
        :object="false"
        v-model="localValue"
        valueProp="id"
        :placeholder="placeholder"
        :noResultsText="'Inga ' + label.toLowerCase() + ' hittades'"
        noOptionsText="Det finns inga alternativ"
        :disabled="!editable"
        :canClear="clearable"
        :canDeselect="deselect"
        :mode="mode"
        :close-on-select="mode == 'multiple' ? false : true"
        @change="updateField"
        :ref="referring"
      />

    </div>
    <div v-if="!fullWidth" class="col-3 col-md-1 d-flex justify-content-center">
        <info-box :field="infoBox" />
      </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'
import InfoBox from '@/components/fields/InfoBox';
import _ from 'lodash'

export default {
  name: 'loader',
  components: {
    Multiselect,
    InfoBox
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    mode: {
      type: String,
      default: 'single',
    },
    multiple: {
      type: String,
      default: 'single',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    deselect: {
      type: Boolean,
      default: false,
    },
    useStore: {
      type: Boolean,
      default: false
    },
    dispatch: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    searchable: {
      type: Boolean,
      default: false
    },
    referring: {
      type: String,
      default: ''
    },
    api: String,
    options: Array,
    model: Object,
    value: Number,
    db_field: String,
    label: String,
    infoBox: String
  },
  watch: {
    value: {
      handler () {
        this.localValue = this.value
      },
      deep: false
    },
  },
  data() {
    return {
      localValue: this.value
    }
  },
  computed: {
    isModelFieldEmpty() {
      if (_.isObject(this.model[this.field])) {
        if (_.isArray(this.model[this.field]) && !this.model[this.field].length) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    updateField(fieldData) {
      let value = '';

      if (fieldData === null) {
        value = null;
      } else {
        if (this.multiple) {
          value = fieldData;
        } else {
          value = fieldData;
        }
      }

      /**
       * TODO: Set dispatch to work dynamically
       */

      if (this.useStore) {
        this.$store.dispatch(this.dispatch, fieldData)
        .then(() => {
          this.$emit('update:value', value);
        })

        return;
      }

      this.$emit('update:value', value);

      const post = {
        field: this.db_field,
        ids: value
      }

      BojAPI[this.api](this.model, post)
        .then(() => {
          this.$emit('fieldSaved');
          this.$toast.clear();
          // this.$emit('fieldSavedWithData', {key: field, value: this.localValue});

          this.$toast.success('Ändringar sparade', {
            position: 'top-center',
          });
        })
        .catch(() => {
          this.$toast.error('Något gick fel', {
            position: 'top-center',
          });
        });
    },
  },
};
</script>
