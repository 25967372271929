<template>
  <span v-if="field.length !== 0">
    <button @click="show" class="btn btn-sm" :style="{color: color}">
      <font-awesome-icon :icon="['fas', 'circle-info']" style="font-size: 25px"/>
    </button>
    <modal :name="infoBox.field" :adaptive="true" :scrollable="true" :resizable="true" height="auto" style="z-index: 9999">
      <div class="container p-4 d-flex flex-column">
        <div class="d-flex justify-content-between">
          <h3 class="mb-4 title">{{ title }}</h3>
        </div>
        <p class="description">{{ description }}</p>
        <button class="btn btn-primary align-self-end" @click="$modal.hide(field)">
          {{ $t('labels.close') }}
        </button>
      </div>
    </modal>
  </span>
</template>

<script>
  export default {
    name: 'InfoBox',
    props: {
      field: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: '',
      }
    },
    computed: {
      infoBox: function () {
        return this.$store.getters.getInfoBox(this.field);
      },
      title: function () {
        return this.$to(this.infoBox,'title');
      },
      description: function () {
        return this.$to(this.infoBox,'description');
      }
    },methods: {
      show () {
        this.$modal.show(this.infoBox.field);
      },
    }
  }
</script>

<style scoped>
  .title {
    font-size: 1.5rem !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
    color: #1a1a1a !important;
    font-family: Poppins !important;
    text-align: left !important;
    text-transform: none !important;
  }

  .description {
    white-space: pre-wrap !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    line-height: 1.5 !important;
    color: #1a1a1a !important;
    font-family: Poppins !important;
    text-align: left !important;
    text-transform: none !important;
  }
</style>
